import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql, Link } from "gatsby"
import { BLOCKS } from '@contentful/rich-text-types';
import React from "react"
import InnerWrapper from "../components/innerWrapper"
import Layout from "../components/layout"
import Share from "../components/share"
import SEO from "../SEO"
import { slugify, toKebabCase } from "../util/utilityFunctions"
import styles from "./scss/blog-post.module.scss"
import GA from "../components/ga"


export const query = graphql`
  query($title: String!) {
    contentfulBlogPost(title: { eq: $title }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      seoDescription
      author
      category
      image {
        fluid(quality: 100, maxWidth: 1920) {
          src
          srcWebp
        }
      }
      body {
        json
      }
    }
  }
`

class Blog extends React.Component {

  render() {

    const tableOfContents = this.props.data.contentfulBlogPost.body.json.content.find(
      (item) => item.nodeType === "unordered-list" || item.nodeType === "ordered-list"
    )

    const tableOfContentsIndex = this.props.data.contentfulBlogPost.body.json.content.findIndex(
      (item) => item.nodeType !== "unordered-list" && item.nodeType !== "ordered-list"
    )

    const hasTableOfContents = tableOfContentsIndex === 1 ? true : false

    const remainingContent = this.props.data.contentfulBlogPost.body.json.content.slice(tableOfContentsIndex)

    const document = {
      nodeType: "document",
      content: hasTableOfContents ? remainingContent : this.props.data.contentfulBlogPost.body.json.content,
      data: {}
    }

    const tableOfContentsOptions = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node) => {
          return (
            <a href={`#${toKebabCase(node.content[0].value)}`}>{node.content[0].value}</a>
          );
        }
      }
    }

    const options = {
      renderNode: {
        [BLOCKS.HEADING_2]: (node) => {
          return (
            <h2 id={toKebabCase(node.content[0].value)}>
              {node.content[0].value}
            </h2>
          );
        },
        [BLOCKS.HEADING_3]: (node) => {
          return (
            <h3 id={toKebabCase(node.content[0].value)}>
              {node.content[0].value}
            </h3>
          );
        },
        [BLOCKS.HEADING_4]: (node) => {
          return (
            <h4 id={toKebabCase(node.content[0].value)}>
              {node.content[0].value}
            </h4>
          );
        },

        "embedded-asset-block": node => {
          const alt = node.data.target.fields.title["en-US"]
          const url = node.data.target.fields.file["en-US"].url
          const width =
            node.data.target.fields.file["en-US"].details.image.width

          return <img alt={alt} src={url} width={width} />
        },
      },
    }

    return (
      <>
        <GA />

        <Layout>
          <SEO
            title={`Articles | ${this.props.data.contentfulBlogPost.title}`}
            pathname={`/articles/${slugify(
              this.props.data.contentfulBlogPost.title
            )}`}
            description={this.props.data.contentfulBlogPost.seoDescription}
          />
          <article className={styles.blogPost}>
            <div className={styles.hero}>
              <picture>
                <source
                  srcSet={
                    this.props.data.contentfulBlogPost.image.fluid.srcWebp
                  }
                  type="image/webp"
                />
                <source
                  srcSet={this.props.data.contentfulBlogPost.image.fluid.src}
                  type="image/png"
                />
                <img
                  className={styles.heroImage}
                  src={this.props.data.contentfulBlogPost.image.fluid.src}
                  alt={this.props.data.contentfulBlogPost.image.description}
                />
              </picture>
            </div>
            <InnerWrapper width={800}>
              <h1>{this.props.data.contentfulBlogPost.title}</h1>

              {
                hasTableOfContents &&
                <>
                  <h3>Table of Contents</h3>
                  {documentToReactComponents(
                    tableOfContents,
                    tableOfContentsOptions
                  )}
                </>
              }
              {documentToReactComponents(
                document,
                options
              )}

              <div className={styles.details}>
                <p>
                  Written by{" "}
                  <Link to="/about#team">
                    <b>{this.props.data.contentfulBlogPost.author}</b>
                  </Link>{" "}
                  {/* on {this.props.data.contentfulBlogPost.publishDate} */}
                </p>
              </div>

              {/* <div className={styles.navigation}>
              {this.props.pageContext.previous && (
                <>
                  <h4>Previous</h4>
                  <Link
                    to={`/article/${slugify(
                      this.props.pageContext.previous.title
                    )}`}
                  >
                    {this.props.pageContext.previous.title}
                  </Link>
                </>
              )}

              {this.props.pageContext.next && (
                <>
                  <h4>Next</h4>
                  <Link
                    to={`/article/${slugify(
                      this.props.pageContext.next.title
                    )}`}
                  >
                    {this.props.pageContext.next.title}
                  </Link>
                </>
              )}
            </div> */}
              {/*   <div className={styles.navigation}>
              <Newsletter
                title="Liked this post?"
                subtitle="Subcribe to our newsletter to get notified of the next one!"
              /> 
            </div>*/}
              <div className={styles.navigation}>
                <Share
                  url="https://www.contentvisit.com/articles/"
                  location={slugify(this.props.data.contentfulBlogPost.title)}
                />
              </div>
            </InnerWrapper>
          </article>
        </Layout>

      </>
    )
  }
}
export default Blog

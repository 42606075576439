import React from "react"
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa"
import styles from "./scss/share.module.scss"
const share = ({ url, location }) => {
  return (
    <section className={styles.share}>
      <h2>Share this post</h2>
      <div className={styles.icons}>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}${location}`}
          title="Share to Facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF className={styles.facebookIcon} />
          Facebook
        </a>

        <a
          href={`https://twitter.com/intent/tweet/?text=Check this out!&url=${url}${location}`}
          title="Share to Twitter"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter className={styles.twitterIcon} />
          Twitter
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}${location}&title=&summary=&source=`}
          title="Share to LinkedIn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn className={styles.linkedinIcon} />
          LinkedIn
        </a>
      </div>
    </section>
  )
}

export default share
